import React from 'react'
import { Helmet } from 'react-helmet'
import Layout from '../../components/Layout'
import JobOfferRoll from '../../components/JobOfferRoll'

export default class FaqIndexPage extends React.Component {
  render() {
    return (
      <Layout>
        <Helmet titleTemplate="%s | GMF Centre Médical St-Hubert">
          <title>{`Offres d'emploi`}</title>
        </Helmet>
        <section className="section section--gradient">
          <div className="container is-max-desktop">
            <h1
              className="has-text-weight-bold is-size-3-mobile is-size-2-tablet is-size-1-widescreen has-text-centered"
              style={{
                lineHeight: '1',
                padding: '0.25em',
              }}
            >
              Nos offres d'emplois
            </h1>
            <section className="section">
              <div className="container is-max-desktop">
                <div className="content">
                  <JobOfferRoll />
                </div>
              </div>
            </section>
          </div>
        </section>
      </Layout>
    )
  }
}
